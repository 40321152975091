.icon {
	width: $icon-size;
	height: $icon-size;

	i, svg {
		font-size: $icon-size - .75;
	}
}


.icon-xl {
	width: $icon-size-xl;
	height: $icon-size-xl;

	i, svg {
		font-size: $icon-size-xl - .75;
	}
}


.icon-lg {
	width: $icon-size-lg;
	height: $icon-size-lg;

	i, svg {
		font-size: $icon-size-lg - .75;
	}
}


.icon-sm {
	width: $icon-size-sm;
	height: $icon-size-sm;

	i, svg {
		font-size: $icon-size-sm - .75;
	}
}



// Icons included in shapes
.icon-shape {
	padding: 12px;
	text-align: center;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	

	i, svg {
		font-size: 1.25rem;
	}

	&.icon-lg {
		i, svg {
			font-size: 1.625rem;
		}	
	}

	&.icon-sm {
		i, svg {
			font-size: .875rem;
		}	
	}

	svg {
		width: 30px;
		height: 30px;
	}

}

@each $color, $value in $theme-colors {
	.icon-shape-#{$color} {
		@include icon-shape-variant(theme-color($color));
	}
} 
